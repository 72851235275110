import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import "./modal.css";

function Help(args) {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <>
      <div className="link-container">
        <div className="nav-link" onClick={toggle}>
          <button id="instructions">Instructions</button>
        </div>
        <div className="nav-link">
          <a
            href="https://www.purinainstitute.com/sign-up"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="btn-sign-up">
              <h4>Sign Up</h4>
            </button>
          </a>
        </div>
      </div>
      <Modal centered={true} isOpen={modal} toggle={toggle} {...args}>
        <ModalBody>
          <button type="button" className="close" onClick={toggle}>
            <img
              src="https://storage.googleapis.com/purina-virtual-experience/design/ui/x.png"
              alt="close"
            />
          </button>
          <img
            alt="instructions"
            src="https://storage.googleapis.com/purina-virtual-experience/micrio/tour-intro.png"
          />
        </ModalBody>
      </Modal>
    </>
  );
}

export default Help;
