import React from "react";
import "./header.css";
import Help from "./Help";

export const Header = (args) => {
  return (
    <header className="app-header">
      <div className="navigation-bar">
        <div className="logo">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.purinainstitute.com/"
          >
            <img
              src="https://storage.googleapis.com/purina-virtual-experience/design/micrio%20assets/PI_Primary_CMYK.png"
              alt="purina institute logo"
            />
          </a>
        </div>
        <Help />
      </div>
    </header>
  );
};
